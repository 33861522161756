import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Link, Typography } from '@mui/material';

export const RecommandedUses = ({
  agreeRecommandedUses,
  setAgreeRecommandedUses,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography paragraph>
        This platform allows you to exchange secrets (texts, passwords,
        keys/token, etc.) between mediarithmics, its clients and its partners,
        in a secure and confidential manner.{' '}
        <b>
          You must comply with our security policies available on SLITE when
          using this platform.
        </b>
      </Typography>
      <Typography paragraph>
        To share a secret, you can create a secret access link and recover a
        password. You can choose the validity period of the link and the number
        of possible consultations. You then need to send the link and password
        to the person you want to share the secret with.
      </Typography>
      <Typography paragraph>
        <b>
          **PLEASE NOTE: it is FORBIDDEN to distribute the access link to the
          secret and its password on a single media (email, IM, doc, etc.). The
          password must always be broadcast on a different media without
          mentioning the secret concerned.**
        </b>{' '}
        For example, you can send the link by email and the password by SMS, or
        vice versa.
      </Typography>
      <Typography paragraph>
        To view a secret, you must click on the secret access link and enter the
        password. You will then be able to see the contents of the secret. If
        the link has expired or the number of views is reached, you will not be
        able to access the secret.
      </Typography>
      <Typography paragraph>
        We thank you for using our secrets sharing platform (
        <Link href="#/" underline="hover">
          secrets.mediarithmics.com
        </Link>
        ) and invite you to contact us if you have any questions or problems.
      </Typography>
      {!agreeRecommandedUses && (
        <Grid container justifyContent="center">
          <Box p={2} pb={4}>
            <Button
              variant="contained"
              onClick={() => {
                setAgreeRecommandedUses(true);
              }}
            >
              <span>{t('recommendedUses.buttonAgreeLabel')}</span>
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};
