import { Route, Routes } from 'react-router-dom';

import CreateSecret from './createSecret/CreateSecret';
import DisplaySecret from './displaySecret/DisplaySecret';
import Upload from './createSecret/Upload';
import { useState } from 'react';

export const Routing = () => {
  const [agreeRecommandedUses, setAgreeRecommandedUses] = useState(false);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <CreateSecret
            agreeRecommandedUses={agreeRecommandedUses}
            setAgreeRecommandedUses={setAgreeRecommandedUses}
          />
        }
      />
      <Route
        path="/upload"
        element={
          <Upload
            agreeRecommandedUses={agreeRecommandedUses}
            setAgreeRecommandedUses={setAgreeRecommandedUses}
          />
        }
      />
      <Route path="/:format/:key/:password" element={<DisplaySecret />} />
      <Route path="/:format/:key" element={<DisplaySecret />} />
    </Routes>
  );
};
